<template>
	<div class="BillDetails">
		
		<h3>
			<i>
				12月对账清单
			</i>
			<span>
				<em>
					<el-select placeholder="请选择请购人">
						<el-option>dsf23s</el-option>
					</el-select>
				</em>
				<em>
					<el-select placeholder="请选择采购人">
						<el-option>12dsf23s</el-option>
					</el-select>
				</em>
				<em>
					<el-select placeholder="请选择供应商">
						<el-option>12dsf23s</el-option>
					</el-select>
				</em>
				<em style="width: 300px;">
					<el-input placeholder="采购单编号、平台订单号、商家订单号"></el-input>
				</em>
				

				<el-button icon="el-icon-search">筛选</el-button>
	
			</span>
			
		</h3>

		<div class="List">
			
			
			<div class="Group" v-for="k in 3" :key="k+'s'">
				<h4>
					<span>采购单号：1212211212（名称：1212211212）</span>
					<em>所属账期:12月</em>
				</h4>
				
				<div class="Title">
					<span class="Check">
						<el-checkbox></el-checkbox>
						全选
					</span>
					<span class="PlatformOrderId">平台订单号</span>
					<span class="PartnerOrderId">商家订单号</span>
					<span class="InvoiceId">发票号码</span>
					<span class="Supplier">供应商</span>
					<span class="Purchaser">采购人</span>
					<span class="OrderInfo">订单信息</span>
					<span class="BillStatus">对账状态</span>
					<span class="FinishStatus">还款状态</span>
					<span class="Action">操作</span>
				</div>
				
				<div class="One" v-for="item in 3" :key="item">
					
					<span class="Check">
						<el-checkbox></el-checkbox>
					</span>
					<span class="PlatformOrderId">12345678
						<li>￥1221</li></span>
					<span class="PartnerOrderId">12345678</span>
					<span class="InvoiceId">12345678</span>
					<span class="Supplier">苏宁</span>
					<span class="Purchaser">阿三哥<li>采购部</li></span>
					<span class="OrderInfo">查看详情<li>11-12 11:12</li></span>
					<span class="BillStatus">释小龙  已对账<li>11-12 11:12</li></span>
					<span class="FinishStatus">还款陈坤 已还款<li>11-12 11:12</li></span>
					<span class="Action">
						<li><em>对账</em></li>
						<li><em>还款</em></li>
					</span>
					
				</div>
			</div>
				
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="1000">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Checkbox,Pagination} from 'element-ui'
	export default {
	  name: 'BillDetails',
	  props: {
	  },
	  data() {
	      return {
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-checkbox':Checkbox
	  },
	  methods:{
	  }
	}
</script>

<style scoped>
.BillDetails{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 30px;
}
.BillDetails h3{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding-bottom: 20px;
}
.BillDetails h3 i{
	width: 120px;
	font-style: normal;
}
.BillDetails h3 span{
	flex: 1;
	display: flex;
	justify-content: flex-end;
}
.BillDetails h3 span em{
	margin-right: 5px;
	width: 150px;
}

.List{
}
.List .Group{
	margin-bottom: 20px;
	border: 1px solid rgba(0,0,0,0.1);
	border-bottom-width: 0px;
}
.List h4{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	margin: 0px;
	font-weight: normal;
	background-color: rgba(0,0,0,0.05);
}
.List h4 span{
	flex: 1;
	text-align: left;
	margin-left: 10px;
}
.List h4 em{
	margin-right: 10px;
}
.List .Title,.List .One{
	display: flex;
}
.List .Title{
	border-bottom: 1px solid rgba(0,0,0,0.1);
	line-height: 45px;
	color: rgba(0,0,0,0.4);
}
.List .One{
	padding: 20px 0px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.List .Check{
	width: 60px;
	margin-left: 10px;
	text-align: left;
}
.List .PlatformOrderId{
	flex: 1;
}
.List .PlatformOrderId li{
	color: rgba(0,0,0,0.3);
}
.List .PartnerOrderId{
	width: 130px;
}
.List .InvoiceId{
	width: 130px;
}

.List .Purchaser{
	width: 90px;
}
.List .Purchaser li{
	width: 90px;
	color: rgba(0,0,0,0.3);
}
.List .OrderInfo{
	width: 130px;
}

.List .OrderInfo li{
	color: rgba(0,0,0,0.3);
}

.List .BillStatus{
	width: 130px;
	}
.List .BillStatus li{
		color: rgba(0,0,0,0.3);
	}

.List .FinishStatus{
	width: 130px;
}

.List .FinishStatus li{
	color: rgba(0,0,0,0.3);
}
.List .Action{
	width: 100px;
	text-align: right;
	margin-right: 10px;
}
.List .Action li em{
	color: rgba(228,0,0,0.9);
}
</style>
